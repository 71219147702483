<script lang="ts">
	let className = '';
	export { className as class };
	export let varient:
		| 'Title-01'
		| 'Title-02'
		| 'Title-03'
		| 'Title-04'
		| 'Title-05'
		| 'body-EB-01'
		| 'body-EB-02'
		| 'body-EB-015'
		| 'body-EB-04'
		| 'body-EB-05'
		| 'body-SB-01'
		| 'body-SB-02'
		| 'body-SB-03'
		| 'body-SB-04'
		| 'body-SB-05'
		| 'body-SB-06'
		| 'body-B-00'
		| 'body-B-01'
		| 'body-B-02'
		| 'body-B-03'
		| 'body-B-04'
		| 'body-B-05'
		| 'body-B-06'
		| 'body-B-07'
		| 'body-B-08'
		| 'body-B-09'
		| 'body-R-01'
		| 'body-R-02'
		| 'body-R-03'
		| 'body-R-04'
		| 'body-R-05'
		| 'body-R-06'
		| 'body-R-07';
</script>

<span class="font-span {varient} {className}"><slot /></span>

<style>
	.font-span {
		display: inline-block;
	}

	.Title-01 {
		/* Title-01 */
		font-size: 70px;
		font-family: Lato;
		font-style: normal;
		font-weight: 700;
		line-height: 120%;
	}

	.Title-02 {
		/* Title-02 */
		font-size: 50px;
		font-family: Lato;
		font-style: normal;
		font-weight: 700;
		line-height: 130%;
	}

	.Title-03 {
		/* Title-03 */
		font-size: 40px;
		font-family: Lato;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
	}

	.Title-04 {
		/* Title-04 */
		font-size: 26px;
		font-family: Lato;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
	}

	.Title-05 {
		/* Title-05 */
		font-size: 18px;
		font-family: Lato;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
	}
	.body-EB-01 {
		/* body-EB-01 */
		font-size: 32px;
		font-family: Lato;
		font-style: normal;
		font-weight: 800;
		line-height: 140%;
	}

	.body-EB-015 {
		/* body-EB-015 */
		font-size: 20px;
		font-family: Lato;
		font-style: normal;
		font-weight: 800;
		line-height: 140%;
	}

	.body-EB-02 {
		/* body-EB-02 */
		font-size: 18px;
		font-family: Lato;
		font-style: normal;
		font-weight: 800;
		line-height: 140%;
	}

	.body-EB-04 {
		/* body-EB-04 */
		font-size: 16px;
		font-family: Lato;
		font-style: normal;
		font-weight: 800;
		line-height: 140%;
	}

	.body-EB-05 {
		/* body-EB-05 */
		font-size: 14px;
		font-family: Lato;
		font-style: normal;
		font-weight: 800;
		line-height: 140%;
	}

	.body-SB-01 {
		/* body-SB-01 */
		font-size: 24px;
		font-family: Lato;
		font-style: normal;
		font-weight: 600;
		line-height: 140%;
	}

	.body-SB-02 {
		/* body-SB-02 */
		font-size: 20px;
		font-family: Lato;
		font-style: normal;
		font-weight: 600;
		line-height: 140%;
	}

	.body-SB-03 {
		/* body-SB-03 */
		font-size: 18px;
		font-family: Lato;
		font-style: normal;
		font-weight: 600;
		line-height: 140%;
	}

	.body-SB-04 {
		/* body-SB-04 */
		font-size: 16px;
		font-family: Lato;
		font-style: normal;
		font-weight: 600;
		line-height: 120%;
	}

	.body-SB-05 {
		/* body-SB-05 */
		font-size: 14px;
		font-family: Lato;
		font-style: normal;
		font-weight: 600;
		line-height: 140%;
	}

	.body-SB-06 {
		/* body-SB-06 */
		font-size: 12px;
		font-family: Lato;
		font-style: normal;
		font-weight: 600;
		line-height: 140%;
	}

	.body-B-00 {
		/* body-B-00 */
		font-size: 50px;
		font-family: Lato;
		font-style: normal;
		font-weight: 700;
		line-height: 120%;
	}

	.body-B-01 {
		/* body-B-01 */
		font-size: 40px;
		font-family: Lato;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
	}

	.body-B-02 {
		/* body-B-02 */
		font-size: 32px;
		font-family: Lato;
		font-style: normal;
		font-weight: 700;
		line-height: 120%;
	}

	.body-B-03 {
		/* body-B-03 */
		font-size: 24px;
		font-family: Lato;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
	}

	.body-B-04 {
		/* body-B-04 */
		font-size: 22px;
		font-family: Lato;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
	}

	.body-B-05 {
		/* body-B-05 */
		font-size: 20px;
		font-family: Lato;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
	}

	.body-B-06 {
		/* body-B-06 */
		font-size: 18px;
		font-family: Lato;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
	}

	.body-B-07 {
		/* body-B-07 */
		font-size: 16px;
		font-family: Lato;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
	}

	.body-B-08 {
		/* body-B-08 */
		font-size: 14px;
		font-family: Lato;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
	}

	.body-B-09 {
		/* body-B-09 */
		font-size: 12px;
		font-family: Lato;
		font-style: normal;
		font-weight: 700;
		line-height: 140%;
	}

	.body-R-01 {
		/* body-R-01 */
		font-size: 26px;
		font-family: Lato;
		font-style: normal;
		font-weight: 400;
		line-height: 140%;
	}

	.body-R-02 {
		/* body-R-02 */
		font-size: 24px;
		font-family: Lato;
		font-style: normal;
		font-weight: 400;
		line-height: 140%;
	}

	.body-R-03 {
		/* body-R-03 */
		font-size: 18px;
		font-family: Lato;
		font-style: normal;
		font-weight: 400;
		line-height: 140%;
	}

	.body-R-04 {
		/* body-R-04 */
		font-size: 17px;
		font-family: Lato;
		font-style: normal;
		font-weight: 400;
		line-height: 140%;
	}

	.body-R-05 {
		/* body-R-05 */
		font-size: 16px;
		font-family: Lato;
		font-style: normal;
		font-weight: 400;
		line-height: 140%;
	}

	.body-R-06 {
		/* body-R-06 */
		font-size: 14px;
		font-family: Lato;
		font-style: normal;
		font-weight: 400;
		line-height: 140%;
	}

	.body-R-07 {
		/* body-R-07 */
		font-size: 12px;
		font-family: Lato;
		font-style: normal;
		font-weight: 400;
		line-height: 140%;
	}
</style>
